import React, { Component } from 'react';
//import styled from 'styled-components';
import ArtSingle from '../PageComponents/ArtSingle'
import ArtThumbnails from '../PageComponents/ArtThumbnails';
import { Route, Switch  } from 'react-router-dom';

let data = require('./../data/works.json');



class Artworks extends Component {
  state = {
    index: 0,
    inactive: false
  };

  

  handleClick = (dir) => {
    let i = this.state.index;
    if (dir === 'next') {
      i = this.state.index < data.length-1 ? i += 1 : 0;
    }
    if (dir === 'back') {
      i = this.state.index > 0 ? i -= 1 : data.length-1;
    }
    this.setState({ index: i });
    this.props.history.push('/work/'+i)
  };

  showThumbnails = () => {
    this.props.history.push('/work')
  };

  selectedThumbnail = (n) => {
    this.setState({
      index: n
    });
  }

  componentDidMount(){
    let imgId;
    if (this.props.match.isExact === false) {
      imgId = this.props.location.pathname;
      imgId = imgId.replace(/\/work\//, '');
      imgId = parseInt(imgId, 10);
      if (imgId >= 0 && imgId < data.length-1) {
        this.setState({index : imgId  })
      }
      else {
        this.props.history.push('/work')
      }
    }
  }

    render() { 
      return(
      <Switch>
      <Route path="/work" exact render={() => (
        <ArtThumbnails 
            index = {this.state.index}
            count = {data.length}
            data ={data}
            selectedThumbnail = {this.selectedThumbnail}
          />
      )} />
      <Route path="/work/:imgId" exact render={() => (
        <ArtSingle 
          index = {this.state.index}
          title = {data[this.state.index].title}
          src = {data[this.state.index].src}
          count = {data.length-1}
          handleClick = {this.handleClick}
          showThumbnails = {this.showThumbnails}
        />  
      )}/>
      </Switch>
      )
    }
}


export default Artworks;