import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Post from '../PageComponents/Post'
import loadingGif from '../img/30.gif'


const Blog = styled.div`

  height: auto; 
  margin: 0px auto 40px;
  text-align: left;
  overflow: auto;

  .loading {
    display: block;
    margin: 40px auto;
  }
`;

// const BlogNavigation = styled.div`
//   li {
//     display: inline-block;
//   }
// `;


class Sketchblog extends Component {
  
  state = {
    posts: [],
    dataReady: false
  }
  
  componentDidMount(){
    const appUrl = "https://dadamancer.org/sketchblog/wp-json/wp/v2/posts?filter[orderby]=date&order=desc";
    
    axios.get(appUrl)
      .then(response => {
        this.setState({posts: response.data}, 
          () => this.setState({dataReady: true})
        )
      })
  }

    render() {
      
      let date;
      let listposts = this.state.posts.map((post, index) => {
        date = post.date.split('T');
        date = date[0].split('-');
        
        return(
          <Post 
            key={index}
            title={post.title.rendered}
            date={date[1] + '/' + date[2] + '/' + date[0]}
            content={post.content.rendered}
          />
        )
      })

      let dataWaiting = <img src={loadingGif} alt="" className="loading" />;
  
      return (
        <Blog className="pageStyle">
          {/*<BlogNavigation>
            <ul>
              <li>back</li>
              <li>next</li>
              <li>search</li>
            </ul>
          </BlogNavigation>*/}
          {this.state.dataReady === false ? dataWaiting : listposts}
        </Blog>      
      );
    }
}


export default Sketchblog;