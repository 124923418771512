import React, { Component } from 'react';
import styled from 'styled-components';


  const Content = styled.div`
    background-color: rgba(0,0,0,0.9);
    height: 100vh;
    left: 0;
    position: fixed;
    top:0;
    transition: all .5s;
    width: 100%;
    z-index: 999;
    
    .image {
        left: 50%;        
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all .5s .3s;
        
        height: calc(100vh - 120px);
        min-height: 400px;
        min-width: 600px;
        width: auto;

        > img {
            max-height: 100%;
            width: auto;
        }
    }
  `;

class Modal extends Component {

    componentDidMount(){
        document.addEventListener('click', this.props.handleClose, false);
    }
    
    componentWillUnmount(){
        document.removeEventListener('click', this.props.handleClose, false);
    }
   
    render() {
      return (
        <Content>
          <div className="image"><img src={'./img/' + this.props.src} alt={this.props.title + ' by Kineta Chien'} /></div>
        </Content>      
      );
    }
}


export default Modal;