import React  from 'react';
import styled from 'styled-components';

const SinglePost = styled.div`

    clear: both;
    overflow: hidden;
    padding: 40px 0;

    &:not(:last-child) {
    border-bottom: solid 1px #ddd; 

    }

    .title {
        font-weight: bold;
        margin-bottom: 6px;
    }

    .date {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .content {
        
    }

    figure {
        display: inline-block;
        margin: 0;
    }

     figure::after {
        clear: both;
    }
        
    img {
        margin: 5px;
        width: auto;
    }

    .left {
        float: left;
        margin-right: 10px;
    }

    .right {
        float: right;
        margin-left: 10px;

    }
    
      
    
`;

const Post = (props) => (

    <SinglePost>
        <div className="title" dangerouslySetInnerHTML={{__html: props.title}}/>
        <div className="date">{props.date}</div>
        <div className="content" dangerouslySetInnerHTML={{__html: props.content}}/>
    </SinglePost>
)

export default Post;