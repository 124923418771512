import React, { Component } from 'react';
//import styled from 'styled-components';
import ArtSingle from '../PageComponents/ArtSingle'
import ArtThumbnails from '../PageComponents/ArtThumbnails'

let data = require('./../data/works.json');



class Home extends Component {

  state = {
    index: 0,
    inactive: false,
    thumbnailView: true,
    initialView: true
  };


  handleClick = (dir) => {
    let i = this.state.index;
    if (dir === 'next') {
      i = this.state.index < data.length-1 ? i += 1 : data.length-1;
    }
    if (dir === 'back') {
      i = this.state.index > 0 ? i -= 1 : 0;
    }
    this.setState({ index: i });
  };

  showThumbnails = () => {
    this.setState({ thumbnailView: true });
  };

  hideThumbnails = () => {
    this.setState({ 
      thumbnailView: false,
      initialView: false
     });
  };

  selectedThumbnail = (n) => {
    this.setState({
      index: n,
      thumbnailView: false,
      initialView: false
    });
  }

    render() { 
      if (this.state.thumbnailView === false) {
        return (
            <ArtSingle 
              index = {this.state.index}
              title = {data[this.state.index].title}
              src = {data[this.state.index].src}
              count = {data.length-1}
              handleClick = {this.handleClick}
              showThumbnails = {this.showThumbnails}
            />  
        );
      } 
      else {
        return (
          <ArtThumbnails 
            index = {this.state.index}
            count = {data.length}
            data ={data}
            initialView={this.state.initialView}
            hideThumbnails = {this.hideThumbnails}
            selectedThumbnail = {this.selectedThumbnail}
          />
        );
    }
        
      
    }
}


export default Home;