import React, { Component } from 'react';
import styled from 'styled-components';

const Product = styled.div`
    display: flex;
    flex-direction: row;
    height: 200px;
    margin-bottom: 30px;
    position: relative;

    h2 {
        font-size: 18px;
        margin: 0 0 10px;
    }

    .productThumb {
        cursor: pointer;
        height: auto;
        width: 300px;

        img {
            height: 200px;
            width: auto;
        }
    }

    .printInfo {
        position: relative;
    }

    .printInfo p {
        font-size: 16px;
        margin: 0 0 5px;
    }

    button {
        bottom: 60px;
        left: 300px;
        position: absolute;
    }
`;


class ProductForm extends Component {

    render() {
        return (
            <Product>
                <div class="productThumb">
                    <img src={'./img/thumbnails/' + this.props.image} alt={this.props.title} onClick={() => {this.props.handleModal(this.props.image)}} /> 
                </div>
                <div>
                    <h2>{this.props.title}</h2>
                    <div className="printInfo">
                        <p>Print size: {this.props.size}</p>
                        <p>Price: {this.props.price}</p>
                    </div>
                </div>
                 <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value={this.props.paypalID} />
                    <button title="PayPal - The safer, easier way to pay online!" name="submit">add to cart</button>
                    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>
            </Product>
    )};
}

export default ProductForm;