import React, { Component } from 'react';
import styled from 'styled-components';


const  Foo = styled.div`
  margin: 0 auto 0;
  max-width: 830px;
  padding: 0;

  }
`;

class Page404 extends Component {
   
  

    render() {
      return (
        <Foo>
          <div className="textSection">The Page You're Looking For Doesn't Exist!</div>
        </Foo>      
      );
    }
}


export default Page404;