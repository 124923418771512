import React, { Component } from 'react';
import styled from 'styled-components';
import { Link  } from 'react-router-dom';
import ArtThumbnail from './ArtThumbnail'


  const Content = styled.div`
      margin: 0 auto;
      max-width: 830px;
      text-align: center;

    .close {
      cursor: pointer;
      font-size: 11px;
      margin: 0 0 16px 8px;
      text-align: left;
    }

    .thumbnailGrid {
      margin: 0 auto;
      text-align: left;
      width: auto;
    }
  `;

class ArtThumbnails extends Component {
  
  thumbnailGrid = () => {
    var grid = [];

    for (var i = 0; i < this.props.count; i++) {
      grid[i] = (
        
        <Link to={'/work/' + i} key={i}>
        <ArtThumbnail           
          src={this.props.data[i].src} 
          alt={this.props.data[i].title} 
          index={i}
          selectedThumbnail={this.props.selectedThumbnail}
        />
        </Link>
      )
    }
    return grid;
  }

    render() {
      return (
        <Content>
          <div className="thumbnailGrid">{this.thumbnailGrid()}</div>
        </Content>      
      );
    }
}


export default ArtThumbnails;