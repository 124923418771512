import React, { Component } from 'react';
import styled from 'styled-components';



const Div = styled.div`
   margin: 0 8px 16px;
   display: inline-block;
   
   > img {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        width: 150px;
   }

   > img:hover {
    box-shadow: 0px 3px 7px rgba(30, 0, 30, 0.35);
   }

   > img:active {
        position: relative;
        top: -1px;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
   }

   &.label {
       vertical-align: top;
       text-align: right;
       height: 150px;
       width: 150px;
   }
`;
 

class ArtThumbnail extends Component {


    render() {        
        // if (this.props.index === 6) {
        //     return (
        //         <React.Fragment>
        //         <Div className="label"><span>older work:</span></Div>
        //         <Div onClick={() => this.props.selectedThumbnail(this.props.index)}>
        //             <img src={'./img/thumbnails/' + this.props.src} alt={this.props.alt} />
        //         </Div>
        //         </React.Fragment>
        //     )
            
        // }
        // else {
        return (
        <Div onClick={() => this.props.selectedThumbnail(this.props.index)}>
            <img src={'./img/thumbnails/' + this.props.src} alt={this.props.alt} />
        </Div>
        );
       //}
    }
}


export default ArtThumbnail;