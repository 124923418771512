import React, { Component } from 'react';
import styled from 'styled-components';

  const Content = styled.div`
    min-width: min-content;
    width: auto;
    
    .image {
      height: calc(100vh - 200px);
      min-height: 400px;
      min-width: 600px;
      width: auto;

      > img {
        max-height: 100%;
        width: auto;
      }
    }

    .title {
      color: #999;
      font-size: 12px;
      height: 40px;
      padding-top: 20px;
      text-align: center;
    }

    .imgNav {
      height: 60px;
      margin: 0 auto;
      padding-top: 20px;
      text-align: center;
      width: 250px;

      i {
        color: #999;
        cursor: pointer;
        margin: 0 20px;
      }

      i:hover, i:active {
        color: #555;
      }

      i.inactive {
        color: #aaa;
        cursor: default
      }
    }
  `;

class ArtSingle extends Component {

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
    document.props = this.props;    
    //document.body.classList.add('darkmode');
  }


  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);    
    //document.body.classList.remove('darkmode');
  }

  handleClickOutside(e){
    if (!e.target.classList.contains('iNav')) {
        e.currentTarget.props.showThumbnails()
    }
  }


    render() {

      return (
        <Content>
          <div className="image"><img src={'../img/' + this.props.src} alt={this.props.title + ' by Kineta Chien'} /></div>
          <div className="title">{this.props.title}</div>
          <div className="imgNav iNav">
            <i onClick={() => this.props.handleClick('back')} className="iNav icon-chevron_left"></i><i onClick={this.props.showThumbnails} className="iNav icon-reorder_square_line" title="thumbnails"></i><i onClick={(e) => this.props.handleClick('next')} className="iNav icon-chevron_right"></i>
          </div>
        </Content>      
      );
    }
}


export default ArtSingle;