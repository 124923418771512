import React, { Component } from 'react';
import styled from 'styled-components';

const PopupBox = styled.div`
 
    background: #fff;
    border: solid 1px #eee; 
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    color: #999;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 13px;
    letter-spacing: 3px;
    padding: 16px 20px 20px;
    position: absolute;
    right: 0px;
    top: 20px; 
    width: 340px;
    z-index: 999;

      a:link,
      a:visited {
        color: #ffbf80;
      }

      a:hover,
      a:active {
        text-decoration: underline dotted !important;
      }
`;

class EmailForm extends Component {
    render() {
     return (
      <PopupBox  id="emailForm" className={this.props.className}>
        Questions? Comments?  I'd love to hear from you! Send me an email at <a href="mailto:saucymambo@kineta.org" target="_blank" without rel="noopener noreferrer">saucymambo at kineta.org</a> (clicking the link will open your default email program).
      </PopupBox>
      );
   }
  }

  export default EmailForm;