import React, { Component } from 'react';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import Nav from './Nav';
import Home from './Home';
import Artworks from './Artworks';
import Sketchblog from './Sketchblog';
import Shop from './Shop';
import Page404 from './Page404';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-153239815-1');


const Header = styled.div`
  color: #666;
  font-size: 24px;
  height: 90px;
  letter-spacing: 3px;
  min-width: 1025px;
  margin-top: 10px;
  position: relative;
  width: 100%;

    > h1 {
      font-size: 24px;
      font-weight: normal
      margin: 0;

        a:link,
        a:visited {
            color: #999;
            text-decoration: none;
        }
    } 
    
    > .nav {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  
`;

const Page = styled.div`
    margin: 0 auto;
    min-width: 70px;
    width: 100%;
    text-align: center;
`;


class App extends Component {
  componentDidMount  = () => ReactGA.pageview(window.location.pathname + window.location.search);
  componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search);

  render() {
    return (
      <BrowserRouter>
        <Header>
          <h1><Link to="/">Kineta Chien</Link></h1>
          <Nav className="nav"/>
        </Header>
        <Page>
          <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/work" component={Artworks} />
          <Route path="/blog" component={Sketchblog} />
          <Route path="/store" exact component={Shop} />
          <Route component={Page404} />
          </Switch>
        </Page>
      </BrowserRouter>
    );
}}

export default App;
