import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import EmailForm from '../PageComponents/EmailForm'

const NavList = styled.div`
  font-size:13px; 
  color:#999; 

  a:link,
  a:visited {
      color: #999;
      text-decoration: none;
  }
  
  a.active,
  a:hover,
  a:active {
    color: #111;
  }

  li {
    display: inline-block;
    position: relative;
    margin-left: 20px;
  }

  i {
    cursor: pointer;
     font-size: 1em;
  }

  .emailForm {
    a:link,
    a:visited {
      color: #ffbf80;
    }

    a:hover,
    a:active {
      text-decoration: underline dotted !important;
    }
  }
`;



class Nav extends Component {
 
  state = {
    showContactDiv: false
  }

  handleContactDiv = () => {
    this.setState({ showContactDiv: !this.state.showContactDiv});
  };

  handleClick = (e) => {
    if(e.target.id !== 'emailForm'){
      this.setState({ showContactDiv: false } ,
        () => document.removeEventListener('click', this.handleClick, false)
      );
    }
}

    render() {


      return (
        <NavList className={this.props.className}>
            <ul>
              <li><NavLink to="/work">artworks</NavLink></li>
              <li><NavLink to="/blog" >sketchbook</NavLink></li>
              <li><NavLink to="/store" >delicious pie</NavLink></li>
              <li>
                <i 
                  className="icon-mail"
                  onClick ={(e) => {
                    this.handleContactDiv(e);
                    document.addEventListener('click', this.handleClick, false);
                    e.stopPropagation();
                  }}
                />
                { this.state.showContactDiv && <EmailForm className="emailForm" /> }
              </li>
              <li><a href="https://www.facebook.com/kineta.chien.artist/" target="_blank" rel="noopener noreferrer" alt="follow me on facebook"><i className="icon-facebook" /></a>
              </li>
          </ul> 
        </NavList>      
      );
    }
}

export default Nav;